import { deleteMessage } from '../deleteMessage';
import { backAndRefresh } from '../backAndRefresh';
import recoverMessage from '../recoverMessage';

jest.mock('../backAndRefresh');
jest.mock('../recoverMessage');

describe('deleteMessage', () => {
  let props;
  let router;
  let location;
  let params;

  beforeEach(() => {
    props = {
      super: {
        setLoading: jest.fn(),
        resetStore: jest.fn(),
      },
      deleteForMe: jest.fn().mockResolvedValue({
        data: {
          createMessageStatus: {
            messageStatus: 'DELETED',
          },
        },
      }),
      store: {
        entity: { id: '123' },
        snackbar: {},
      },
    };
    router = {};
    location = {};
    params = { message_id: 'message-id' };
    jest.clearAllMocks();
  });

  it('should call deleteForMe and update snackbar with success message', async () => {
    await deleteMessage(params, router, location, props, true);

    expect(props.super.setLoading).toHaveBeenCalledWith(true);
    expect(props.deleteForMe).toHaveBeenCalledWith({
      variables: {
        CreateMessageStatusMutation: {
          messageId: 'message-id',
          entityId: '123',
          status: 'DELETED',
        },
      },
    });

    await Promise.resolve();

    expect(props.store.snackbar).toEqual({
      active: true,
      message: 'Message deleted',
      success: true,
      action: 'Undo',
      dismissAfter: 10000,
      onClick: expect.any(Function),
    });

    expect(backAndRefresh).toHaveBeenCalledWith(
      props.store,
      router,
      location,
      true,
      false,
    );
    expect(recoverMessage).not.toHaveBeenCalled();
  });

  it('should call recoverMessage when hasUndoOption is true', async () => {
    await deleteMessage(params, router, location, props, true);

    const onClick = props.store.snackbar.onClick;
    onClick();

    expect(recoverMessage).toHaveBeenCalledWith(false, 'DELETED', props, true);
  });

  it('should not call backAndRefresh when hasUndoOption is false', async () => {
    await deleteMessage(params, router, location, props, false);

    expect(backAndRefresh).not.toHaveBeenCalled();
  });

  it('should call resetStore if resetStore is available', async () => {
    props.super.resetStore = jest.fn();

    await deleteMessage(params, router, location, props, true);

    expect(props.super.resetStore).toHaveBeenCalled();
  });
});
