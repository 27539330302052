import { backAndRefresh } from '../backAndRefresh';

describe('backAndRefresh', () => {
  let store, router, location;

  beforeEach(() => {
    store = {
      history: [],
      previous: {
        params: {},
      },
    };
    router = {
      location: { search: '' },
      push: jest.fn(),
      replace: jest.fn(),
      goBack: jest.fn(),
    };
    location = { search: '' };
  });

  it('should go back to the last route when "undo" is true', () => {
    store.history = ['/', '/page1'];
    router.location.search = '?query=1';

    backAndRefresh(store, router, location, false, true);

    expect(router.replace).toHaveBeenCalledWith('/page1?query=1');
  });

  it('should go back to the second to last route when "isLegacy" is true', () => {
    store.history = ['/', '/page1', '/page2'];
    router.location.search = '?query=1';

    backAndRefresh(store, router, location, true, false);

    expect(router.replace).toHaveBeenCalledWith('/page1?query=1');
  });

  it('should call router.goBack() when neither "undo" nor "isLegacy" are set', () => {
    store.history = ['/', '/page1'];

    backAndRefresh(store, router, location);

    expect(router.goBack).toHaveBeenCalled();
  });

  it('should navigate to the messages page with entity when entity_id is present', () => {
    store.previous.params.entity_id = '123';
    store.history = ['/'];

    location.search = '?query=1';

    backAndRefresh(store, router, location);

    expect(router.push).toHaveBeenCalledWith('/entities/123/messages?query=1');
  });

  it('should navigate to the messages page with organization when organization_id is present', () => {
    store.previous.params.organization_id = 'org123';
    store.history = ['/'];

    backAndRefresh(store, router, location);

    expect(router.push).toHaveBeenCalledWith('/organizations/org123/messages');
  });

  it('should navigate to the homepage ("/") when neither entity_id nor organization_id are present', () => {
    store.previous.params = {};
    store.history = ['/'];

    backAndRefresh(store, router, location);

    expect(router.push).toHaveBeenCalledWith('/');
  });

  it('should verify correct link without search query', () => {
    store.previous.params.entity_id = '456';
    store.history = ['/'];

    backAndRefresh(store, router, location);

    expect(router.push).toHaveBeenCalledWith('/entities/456/messages');
  });

  it('should ensure navigation works correctly when history has only one item', () => {
    store.history = ['/'];

    backAndRefresh(store, router, location);

    expect(router.push).toHaveBeenCalledWith('/');
  });
});
