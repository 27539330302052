/**
 *
 * @param {{
 *  values: {
 *    medias: any[],
 *    surveys: any[],
 *    commitments: any[],
 *    reports: any[],
 *    charges: any[],
 *    forms: any[],
 *    signatures: any[]
 *  }
 * }} props
 */
export function hasAttachments({ values }) {
  return !!(
    values?.medias?.length ||
    values?.surveys?.length ||
    values?.commitments?.length ||
    values?.reports?.length ||
    values?.charges?.length ||
    values?.forms?.length ||
    values?.signatures?.length
  );
}
