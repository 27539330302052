export function filterSelectedRecipients({ recipients, groups }) {
  return recipients.filter((recipient) => {
    const isRecipientSelected = recipient.selected;

    // search for the group that contains the recipient
    const groupWithRecipient = groups.find((group) =>
      group.entities.nodes.some((entity) => entity.id == recipient.id),
    );
    const isGroupSelected = groupWithRecipient?.selected || false;

    /**
     * if group is selected, return false
     * because recipient is already included in the group
     */
    if (isGroupSelected) {
      return false;
    }

    return isRecipientSelected;
  });
}
