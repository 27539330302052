import gql from 'graphql-tag';

const RESEND_COMMUNICATION_MUTATION = gql`
  mutation resendMessageNetwork($messageId: ID!) {
    resendMessageNetwork(input: { messageId: $messageId }) {
      success
      error
    }
  }
`;

export function resendCommunication(client, messageId) {
  return client.mutate({
    mutation: RESEND_COMMUNICATION_MUTATION,
    variables: {
      messageId,
    },
    refetchQueries: [
      'EntityMessagesQuery',
      'MessageNodeEntityQuery',
      'OrganizationMessagesQuery',
      'MessageNodeOrganizationQuery',
    ],
  });
}
