import { getMediasFromInput } from '../getMediasFromInput';

describe('getMediasFromInput', () => {
  it('should return an empty array if input is undefined', () => {
    expect(getMediasFromInput()).toEqual([]);
  });

  it('should retun [] if input is empty', () => {
    expect(getMediasFromInput('')).toEqual([]);
  });

  it('should return medias.map if input is not empty', () => {
    const result = getMediasFromInput({
      medias: [
        {
          filename: '9c61fbacec8d40670d6737f1f15d8ec4.bin',
          type: 'file',
          uri: 'https://test-file.txt.com',
          origName: 'test-file.txt',
        },
        {
          filename: 'c11bcf80b535d865972f7a4ea6ff585c.doc',
          type: 'file',
          uri: 'https://doc-example.doc.com',
          origName: 'doc-example.doc',
        },
        {
          filename: 'eec8782e53ae0f9ec36396f57827390f.png',
          type: 'image',
          uri: 'https://test-image.png.com',
          origName: 'test-image.png',
        },
      ],
    });

    expect(result).toEqual([
      {
        name: 'test-file.txt',
        preview: 'https://test-file.txt.com',
        response: {
          filename: '9c61fbacec8d40670d6737f1f15d8ec4.bin',
          uri: 'https://test-file.txt.com',
        },
        thumbnail: null,
        type: 'file',
      },
      {
        name: 'doc-example.doc',
        preview: 'https://doc-example.doc.com',
        response: {
          filename: 'c11bcf80b535d865972f7a4ea6ff585c.doc',
          uri: 'https://doc-example.doc.com',
        },
        thumbnail: null,
        type: 'file',
      },
      {
        name: 'test-image.png',
        preview: 'https://test-image.png.com',
        response: {
          filename: 'eec8782e53ae0f9ec36396f57827390f.png',
          uri: 'https://test-image.png.com',
        },
        thumbnail: null,
        type: 'image',
      },
    ]);
  });
});
