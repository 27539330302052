/**
 * @param {{
 *  shouldShowButton: boolean
 * }}
 */
export function getAttachmentButtonStyles({
  shouldShowButton = false,
  hasPersonalizedFilesAdded = false,
}) {
  let styles = {
    button: {},
    icon: {},
  };

  if (shouldShowButton) {
    styles = {
      button: {
        margin: '0px 4px',
        borderRadius: '0.5rem',
        padding: '0.5rem',
        minHeight: '36px',
        border: hasPersonalizedFilesAdded
          ? '1px solid rgba(0,0,0,.3)'
          : 'unset',
      },
      icon: {
        fontSize: '0.875rem',
      },
    };
  }

  return styles;
}
