import { filterSelectedRecipients } from '../filterSelectedRecipients';

describe('given filterSelectedRecipients helper', () => {
  const mockedParams = {
    groups: [
      {
        selected: true,
        entities: {
          nodes: [
            {
              id: 1,
            },
          ],
        },
      },
      {
        selected: false,
        entities: {
          nodes: [
            {
              id: 2,
            },
          ],
        },
      },
    ],
    recipients: [
      {
        id: 1,
        selected: true,
      },
      {
        id: 2,
        selected: true,
      },
      {
        id: 3,
        selected: false,
      },
    ],
  };

  it('should be able to return recipient with ID 2, because the ID 1 it is inside the first group and ID 3 is not selected', () => {
    const result = filterSelectedRecipients(mockedParams);
    expect(result).toEqual([
      {
        id: 2,
        selected: true,
      },
    ]);
  });
});
