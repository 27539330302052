/* eslint-disable @typescript-eslint/no-var-requires */
import { formatMessageContent } from '../formatMessageContent';

describe('formatMessageContent', () => {
  it('should format the message content correctly', () => {
    const input = 'Hello,  world,  how, are, you, ';
    const expectedOutput = 'Hello, world, how, are, you';

    const result = formatMessageContent(input);

    expect(result).toEqual(expectedOutput);
  });

  it('should handle empty strings correctly', () => {
    const input = '';
    const expectedOutput = '';

    const result = formatMessageContent(input);

    expect(result).toEqual(expectedOutput);
  });

  it('should remove extra spaces between commas', () => {
    const input = '  ,   ,  , ';
    const expectedOutput = '';

    const result = formatMessageContent(input);

    expect(result).toEqual(expectedOutput);
  });

  it('should remove trailing commas', () => {
    const input = 'Hello, world, ';
    const expectedOutput = 'Hello, world';

    const result = formatMessageContent(input);

    expect(result).toEqual(expectedOutput);
  });

  it('should handle input with no commas', () => {
    const input = 'Hello world';
    const expectedOutput = 'Hello world';

    const result = formatMessageContent(input);

    expect(result).toEqual(expectedOutput);
  });

  it('should replace multiple commas with a single comma and trim spaces', () => {
    const input = 'Hello,,,world,,how,,are,,,you,,,';
    const expectedOutput = 'Hello, world, how, are, you';

    const result = formatMessageContent(input);

    expect(result).toEqual(expectedOutput);
  });

  it('should handle strings with spaces and commas only', () => {
    const input = '   , ,  ,   , ';
    const expectedOutput = '';

    const result = formatMessageContent(input);

    expect(result).toEqual(expectedOutput);
  });

  it('should handle input with non-alphanumeric characters', () => {
    const input = 'Hello, world!@#, $, &, *, (, ), ';
    const expectedOutput = 'Hello, world!@#, $, &, *, (, )';

    const result = formatMessageContent(input);

    expect(result).toEqual(expectedOutput);
  });
});
