import { base64ToUint8Array } from './base64ToUint8Array';
import { formatMessageContent } from './formatMessageContent';

export function handleForwardMessage(setState) {
  const isForwardMessage =
    sessionStorage.getItem('forwardMessageSubject') !== null &&
    sessionStorage.getItem('forwardMessageContent') !== null;

  if (isForwardMessage) {
    const forwardMessageSubject = sessionStorage.getItem(
      'forwardMessageSubject',
    );
    const forwardMessageContent = sessionStorage.getItem(
      'forwardMessageContent',
    );

    const textDecoder = new TextDecoder('utf-8');

    const decodeForwardMessageSubject = forwardMessageSubject
      ? textDecoder.decode(base64ToUint8Array(forwardMessageSubject)).trim()
      : '';
    const decodeForwardMessageContent = forwardMessageContent
      ? textDecoder.decode(base64ToUint8Array(forwardMessageContent)).trim()
      : '';

    const cleanSubject = formatMessageContent(decodeForwardMessageSubject);
    const cleanContent = formatMessageContent(decodeForwardMessageContent);

    const values = {
      subject: cleanSubject,
      content: cleanContent,
    };

    setState({ values });

    sessionStorage.removeItem('forwardMessageSubject');
    sessionStorage.removeItem('forwardMessageContent');
  }
}
