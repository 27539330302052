import React from 'react';
import { infosWeb } from '../getInfosWeb';
import { Icon } from 'semantic-ui-react';
import { isNetwork } from '../../../../app/Domain/UseCases/Unleash/validations/IsNetwork';
import { __ } from '../../../../i18n';

jest.mock('../../../../app/Domain/UseCases/Unleash/validations/IsNetwork');

describe('getInfosWeb', () => {
  beforeEach(() => {
    jest.clearAllMocks();
  });

  const storeNetwork = { currentOrganization: { id: 1, network: true } };

  it('should return all infos', () => {
    const store = {
      currentOrganization: {
        id: 1,
      },
    };
    const sent = 1;
    const replied = {
      totalCount: 1,
    };
    const received = {
      totalCount: 1,
    };
    const read = {
      totalCount: 1,
    };

    const result = infosWeb(store, sent, replied, received, read);

    expect(result).toEqual([
      {
        name: 'Sent',
        summary: sent,
        icon: <Icon name="check" />,
        style: { padding: '21px 10px 21px 0px' },
      },
      {
        name: 'Received',
        summary: received.totalCount,
        icon: <Icon color="black" name="check double" />,
        // eslint-disable-next-line sonarjs/no-duplicate-string
        style: { padding: '21px 0px 21px 10px' },
      },
      {
        name: 'Read',
        summary: read.totalCount,
        icon: <Icon name="check double" style={{ color: '#0080FF' }} />,
        style: { padding: '21px 0px 21px 10px' },
      },
      {
        name: 'Replied',
        summary: replied.totalCount,
        icon: <Icon name="reply" />,
        style: { padding: '21px 0px 21px 10px' },
      },
    ]);
  });

  it('should return correct info when counts are zero', () => {
    isNetwork.mockReturnValue(true);
    const sent = 0;
    const replied = { totalCount: 0 };
    const received = { totalCount: 0 };
    const read = { totalCount: 0 };

    const result = infosWeb(storeNetwork, sent, replied, received, read);

    expect(result).toEqual([
      {
        name: __('Sent'),
        summary: sent,
        icon: <Icon name="check" />,
        style: { padding: '21px 10px 21px 0px' },
      },
      {
        name: __('Received'),
        summary: received.totalCount,
        icon: <Icon color="black" name="check double" />,
        style: { padding: '21px 0px 21px 10px' },
      },
      {
        name: __('Read'),
        summary: read.totalCount,
        icon: <Icon name="check double" style={{ color: '#0080FF' }} />,
        style: { padding: '21px 0px 21px 10px' },
      },
    ]);
  });

  it('should return three infos when is a network and counts are non-zero', () => {
    isNetwork.mockReturnValue(true);
    const sent = 1;
    const replied = { totalCount: 0 };
    const received = { totalCount: 2 };
    const read = { totalCount: 3 };

    const result = infosWeb(storeNetwork, sent, replied, received, read);

    expect(result).toEqual([
      {
        name: __('Sent'),
        summary: sent,
        icon: <Icon name="check" />,
        style: { padding: '21px 10px 21px 0px' },
      },
      {
        name: __('Received'),
        summary: received.totalCount,
        icon: <Icon color="black" name="check double" />,
        style: { padding: '21px 0px 21px 10px' },
      },
      {
        name: __('Read'),
        summary: read.totalCount,
        icon: <Icon name="check double" style={{ color: '#0080FF' }} />,
        style: { padding: '21px 0px 21px 10px' },
      },
    ]);
  });
});
