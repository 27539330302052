import { base64ToUint8Array } from '../base64ToUint8Array';

describe('base64ToUint8Array', () => {
  it('should convert base64 string to Uint8Array', () => {
    const base64 = 'SGVsbG8gd29ybGQ=';
    const expectedArray = new Uint8Array([
      72, 101, 108, 108, 111, 32, 119, 111, 114, 108, 100,
    ]);

    const result = base64ToUint8Array(base64);

    expect(result).toEqual(expectedArray);
  });

  it('should handle empty base64 string', () => {
    const base64 = '';
    const expectedArray = new Uint8Array([]);

    const result = base64ToUint8Array(base64);

    expect(result).toEqual(expectedArray);
  });

  it('should handle base64 string with special characters', () => {
    const base64 = 'Q2hhVCBHQyEj';
    const expectedArray = new Uint8Array([67, 104, 97, 84, 32, 71, 67, 33, 35]);

    const result = base64ToUint8Array(base64);

    expect(result).toEqual(expectedArray);
  });

  it('should throw an error for invalid base64 string', () => {
    const base64 = 'InvalidBase64@';

    expect(() => base64ToUint8Array(base64)).toThrowError();
  });

  it('should correctly handle padding in base64 string', () => {
    const base64 = 'U3BhY2VzIA==';
    const expectedArray = new Uint8Array([83, 112, 97, 99, 101, 115, 32]);

    const result = base64ToUint8Array(base64);

    expect(result).toEqual(expectedArray);
  });

  it('should correctly handle multibyte characters', () => {
    const base64 = '8J+RiA==';
    const expectedArray = new Uint8Array([240, 159, 145, 136]);

    const result = base64ToUint8Array(base64);

    expect(result).toEqual(expectedArray);
  });
});
