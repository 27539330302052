import { validateReports } from '../validateReports';

describe('validateReports', () => {
  it('should return null if reports are not provided', () => {
    expect(validateReports(null)).toBeNull();
    expect(validateReports(undefined)).toBeNull();
  });

  it('should handle reports with no fields', () => {
    const reports = [
      { id: 1, fields: null, multiple: false },
      { id: 2, fields: [], multiple: false },
    ];

    const result = validateReports(reports);

    expect(result).toEqual([]);
  });

  it('should not include null or empty result fields in the output', () => {
    const reports = [
      {
        id: 1,
        fields: [{ id: 'a', type: 'CHECK', result: [] }],
        multiple: false,
      },
    ];

    const result = validateReports(reports);

    expect(result).toEqual([]);
  });

  it('should handle fields with non-SELECT type correctly', () => {
    const reports = [
      {
        id: 1,
        fields: [{ id: 'a', type: 'TEXT', result: 'Some text' }],
        multiple: false,
      },
    ];

    const result = validateReports(reports);

    expect(result).toEqual([
      {
        reportId: 1,
        value: 'Some text',
        reportFieldId: 'a',
      },
    ]);
  });

  it('should handle reports with multiple fields and entities', () => {
    const reports = [
      {
        id: 1,
        fields: [
          {
            id: 'a',
            type: 'CHECK',
            result: [{ selected: true, value: 'val1' }],
          },
          {
            id: 'b',
            type: 'CHECK',
            result: [{ selected: false, value: 'val2' }],
          },
        ],
        entities: [
          {
            id: 'entity1',
            fields: [
              [
                { selected: true, value: 'entity1_val1' },
                { selected: false, value: 'entity1_val2' },
              ],
              [{ selected: false, value: 'entity2_val1' }],
            ],
          },
        ],
        multiple: true,
      },
    ];

    const result = validateReports(reports);

    expect(result).toEqual([
      {
        reportId: 1,
        value: '["entity1_val1"]',
        reportFieldId: 'a',
        entityId: 'entity1',
      },
    ]);
  });
});
