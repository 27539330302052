/* eslint-disable prettier/prettier */
import { handleForwardMessage } from '../handleForwardMessage';
import { base64ToUint8Array } from '../base64ToUint8Array.js';
import { formatMessageContent } from '../formatMessageContent.js';

jest.mock('../base64ToUint8Array.js');
jest.mock('../formatMessageContent.js');

global.TextDecoder = class {
  decode(buffer) {
    return String.fromCharCode(...buffer);
  }
};

describe('handleForwardMessage', () => {
  let setStateMock;

  beforeEach(() => {
    setStateMock = jest.fn();
    sessionStorage.clear();
  });

  it('should update state when forward message data is available', () => {
    sessionStorage.setItem('forwardMessageSubject', 'SGVsbG8gd29ybGQ=');
    sessionStorage.setItem('forwardMessageContent', 'V29ybGQgdGhhdCBkYXRh');

    base64ToUint8Array.mockImplementation((base64) =>
      base64 === 'SGVsbG8gd29ybGQ='
        ? new Uint8Array([72, 101, 108, 108, 111, 32, 119, 111, 114, 108, 100])
        : new Uint8Array([
          87, 111, 114, 108, 100, 32, 116, 104, 97, 116, 32, 100, 97, 116, 97,
        ]),
    );
    formatMessageContent.mockImplementation((input) => input.trim());

    handleForwardMessage(setStateMock);

    expect(setStateMock).toHaveBeenCalledWith({
      values: { subject: 'Hello world', content: 'World that data' },
    });
    expect(sessionStorage.getItem('forwardMessageSubject')).toBeNull();
    expect(sessionStorage.getItem('forwardMessageContent')).toBeNull();
  });

  it('should not update state when forward message data is missing', () => {
    handleForwardMessage(setStateMock);

    expect(setStateMock).not.toHaveBeenCalled();
  });
});