import { hasCustomFileButton } from '../../../../app/Domain/UseCases/Unleash/validations/HasCustomFileButton';
import { isNetwork } from '../../../../app/Domain/UseCases/Unleash/validations/IsNetwork';

/**
 *
 * @param {{
 *  plan: string,
 *  unleashStatus: {
 *   custom_media_button: boolean
 *   custom_media: boolean
 *  }
 * }} organization
 * @param {{
 *  type: string
 * }} entity
 */

export function shouldShowButton({ organization, entity, isEditPage = false }) {
  if (!organization || !entity || isEditPage) return false;

  const isPremiumPlan = organization?.plan === 'PREMIUM';
  const isAdminOrStaff = entity?.type === 'ADMIN' || entity?.type === 'STAFF';

  return (
    hasCustomFileButton(organization) &&
    !isNetwork(organization) &&
    isPremiumPlan &&
    isAdminOrStaff
  );
}
