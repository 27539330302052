/**
 * @param {{
 *  isMobile: boolean,
 *  shouldShowButton: boolean
 * }}
 */
export function getMediaButtonStyles({
  isMobile = false,
  shouldShowButton = false,
  hasPersonalizedFilesAdded = false,
}) {
  let styles = {};

  if (isMobile) {
    styles = {
      ...styles,
      paddingTop: '11px',
    };
  }

  if (shouldShowButton) {
    styles = {
      ...styles,
      borderRadius: '0.5rem',
      padding: '0.5rem',
      display: 'flex',
      margin: '0px 4px',
      minHeight: '36px',
      border: hasPersonalizedFilesAdded ? '1px solid rgba(0,0,0,.3)' : 'unset',
    };
  }

  return styles;
}
