import { openKnowMoreModal } from '../openKnowMoreModal';

describe('openKnowMoreModal', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      store: {
        appends: {
          push: jest.fn(),
        },
        entity: {
          type: 'ADMIN',
        },
        currentEntity: {
          type: 'ADMIN',
        },
      },
    };
  });

  describe('when props is not provided', () => {
    it('should return false when store is not provided', () => {
      props.store = null;
      const result = openKnowMoreModal({ ...props });
      expect(result).toBe(false);
    });
  });

  describe('when entity is provided', () => {
    it('should called store appends when entity type is STAFF', () => {
      props.store.entity.type = 'STAFF';
      openKnowMoreModal({ ...props });
      expect(props.store.appends.push).toBeCalledTimes(1);
    });

    it('should called store appends when entity type is ADMIN', () => {
      openKnowMoreModal({ ...props });
      expect(props.store.appends.push).toBeCalledTimes(1);
    });
  });

  describe('when currentEntity is provided', () => {
    it('should called store appends when current entity type is STAFF', () => {
      props.store.entity = null;
      props.store.currentEntity.type = 'STAFF';
      openKnowMoreModal({ ...props });
      expect(props.store.appends.push).toBeCalledTimes(1);
    });

    it('should called store appends when current entity type is ADMIN', () => {
      props.store.entity = null;
      openKnowMoreModal({ ...props });
      expect(props.store.appends.push).toBeCalledTimes(1);
    });
  });
});
