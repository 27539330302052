import { createInputMessageToApprove } from '../createInputMessageToApprove';
import { validateForms } from '../../../utils/validateForms';
import { validateCharges } from '../../../utils/validateCharges';

describe('createInputMessageToApprove', () => {
  it('should return null when passed empty input', () => {
    const values = {};
    const approverId = null;
    const selectedAll = false;
    const expectedOutput = null;
    const result = createInputMessageToApprove(values, approverId, selectedAll);
    expect(result).toEqual(expectedOutput);
  });

  it('should return an object with the necessary data to send an approval message', () => {
    const values = {
      entity: {
        messageToApprove: {
          id: 1,
        },
      },
      allRecipients: [
        {
          id: 1,
          selected: true,
        },
      ],
      allGroups: [],
      tags: [],
    };
    const approverId = 1;
    const selectedAll = false;
    const expectedOutput = {
      messageToApproveId: 1,
      entityApproverId: 1,
      recipients: {
        entityIds: [1],
        groupIds: [],
      },
      medias: [],
      tags: [],
    };
    const result = createInputMessageToApprove(values, approverId, selectedAll);
    expect(result).toEqual(expectedOutput);
  });

  it('should validate charges', () => {
    const charges = [
      { id: 1, name: 'Charge 1' },
      { id: 2, name: 'Charge 2' },
    ];
    const expectedOutput = [
      {
        name: 'Charge 1',
        absorbedFee: [],
        allowedInstallments: undefined,
        amount: undefined,
        description: '',
        organizationAccountId: undefined,
        paymentMethods: undefined,
      },
      {
        name: 'Charge 2',
        absorbedFee: [],
        allowedInstallments: undefined,
        amount: undefined,
        description: '',
        organizationAccountId: undefined,
        paymentMethods: undefined,
      },
    ];
    const result = validateCharges(charges);
    expect(result).toEqual(expectedOutput);
  });

  it('should return empty array if charges is empty', () => {
    const charges = [];
    const result = validateCharges(charges);
    expect(result).toEqual([]);
  });

  it('should return undefined if charges is null or undefined', () => {
    const charges = null;
    const result = validateCharges(charges);
    expect(result).toBeNull();
  });
});

it('should validate forms', () => {
  const forms = [{ id: 1 }, { id: 2 }];
  const result = validateForms(forms);
  expect(result).toEqual(forms);
});

it('should return empty array if forms is empty', () => {
  const forms = [];
  const result = validateForms(forms);
  expect(result).toEqual([]);
});

it('should return undefined if forms is null or undefined', () => {
  const forms = null;
  const result = validateForms(forms);
  expect(result).toBeNull();
});
