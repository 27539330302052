export function getRecipients(groups, entities) {
  let recipients = [];

  if (entities && entities.length > 0)
    recipients = entities.map((entity) => ({
      id: entity.id,
      fullname: entity.fullname,
      groups: entity.roles?.nodes ?? [],
    }));

  if (groups && groups.length > 0) {
    groups.forEach((group) => {
      group.entities.nodes.forEach((entity) => {
        if (!recipients.some((recipient) => recipient.id === entity.id)) {
          recipients.push({
            id: entity.id,
            fullname: entity.fullname,
            groups: entity.roles?.nodes ?? [],
          });
        }
      });
    });
  }

  return recipients;
}
