import { getAttachmentButtonStyles } from '../getAttachmentButtonStyles';

describe('getAttachmentButtonStyles', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      shouldShowButton: false,
      hasPersonalizedFilesAdded: false,
    };
  });

  it('should return empty object when props is not provided', () => {
    const result = getAttachmentButtonStyles({});
    expect(result).toStrictEqual({
      button: {},
      icon: {},
    });
  });

  it('should return empty object when shouldShowButton is false', () => {
    const result = getAttachmentButtonStyles(props);
    expect(result).toStrictEqual({
      button: {},
      icon: {},
    });
  });

  it('should return style object when shouldShowButton and hasPersonalizedFilesAdded is true', () => {
    props.shouldShowButton = true;
    props.hasPersonalizedFilesAdded = true;
    const result = getAttachmentButtonStyles(props);
    expect(result).toStrictEqual({
      button: {
        margin: '0px 4px',
        borderRadius: '0.5rem',
        padding: '0.5rem',
        minHeight: '36px',
        border: '1px solid rgba(0,0,0,.3)',
      },
      icon: {
        fontSize: '0.875rem',
      },
    });
  });
});
