import { validateEntitySender } from '../validateEntitySender';

describe('validateEntitySender', () => {
  let context;
  let values;

  beforeEach(() => {
    context = {
      props: {
        store: {
          currentEntity: { id: 1 },
          appends: [],
          snackbar: {},
        },
        router: {
          goBack: jest.fn(),
        },
      },
      setState: jest.fn((state, callback) => callback()),
    };

    values = {
      entityId: '1',
    };
  });

  it('should return true if entityId matches currentEntity id', () => {
    expect(validateEntitySender.call(context, values)).toBe(true);
  });

  it('should return false and perform actions if entityId does not match currentEntity id', () => {
    values.entityId = '2';
    context.props.store.appends = ['item1'];

    expect(validateEntitySender.call(context, values)).toBe(false);
    expect(context.props.store.appends).toEqual([]);
    expect(context.setState).toHaveBeenCalledWith(
      { onLeave: true },
      expect.any(Function),
    );
    expect(context.props.router.goBack).toHaveBeenCalled();
    expect(context.props.store.snackbar).toEqual({
      active: true,
      message: 'Not authorized',
      success: false,
    });
  });
});
