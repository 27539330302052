import { getChargesFromInput } from '../getChargesFromInput';

describe('getChargesFromInput', () => {
  it('should return an empty array if input is empty', () => {
    expect(getChargesFromInput('')).toEqual([]);
  });

  it('should return an empty array if input is not a valid JSON', () => {
    expect(getChargesFromInput('invalid json')).toEqual([]);
  });

  it('should return an empty array if charges are not present in the input', () => {
    const input = JSON.stringify({ otherKey: [] });
    expect(getChargesFromInput(input)).toEqual([]);
  });

  it('should return charges with absorbBoleto, absorbPix, and absorbCard properties', () => {
    const input = JSON.stringify({
      charges: [
        { absorbedFee: ['boleto', 'pix'], amount: 100 },
        { absorbedFee: ['card'], amount: 200 },
      ],
    });

    const expectedOutput = [
      {
        absorbedFee: ['boleto', 'pix'],
        amount: 100,
        absorbBoleto: true,
        absorbPix: true,
        absorbCard: false,
      },
      {
        absorbedFee: ['card'],
        amount: 200,
        absorbBoleto: false,
        absorbPix: false,
        absorbCard: true,
      },
    ];

    expect(getChargesFromInput(input)).toEqual(expectedOutput);
  });

  it('should handle charges with no absorbedFee property', () => {
    const input = JSON.stringify({
      charges: [{ amount: 100 }],
    });

    expect(getChargesFromInput(input)).toEqual([]);
  });

  it('should return charges.map if input is not empty', () => {
    const result = getChargesFromInput(
      '{"charges": [{"name": "charge1", "amount": 100, "absorbedFee": ["pix", "boleto"]}, {"name": "charge2", "amount": 200, "absorbedFee": ["card"]}] }',
    );
    expect(result).toEqual([
      {
        absorbBoleto: true,
        absorbCard: false,
        absorbPix: true,
        absorbedFee: ['pix', 'boleto'],
        amount: 100,
        name: 'charge1',
      },
      {
        absorbBoleto: false,
        absorbCard: true,
        absorbPix: false,
        absorbedFee: ['card'],
        amount: 200,
        name: 'charge2',
      },
    ]);
  });

  it('should return [] if input is none', () => {
    const result = getChargesFromInput(null);
    expect(result).toEqual([]);
  });
});
