import React from 'react';
import { isEntityType } from '../../../../utils';
import PaywallModal from '../../../Trial/PaywallModal';
import KnowMoreModal from '../../../../components/KnowMoreModal';

/**
 *
 * @param {{
 *  store: {
 *   appends: [],
 *   entity: {
 *    type: string,
 *   }
 *   currentEntity: {
 *    type: string,
 *   }
 * }
 * }} props
 */

export function openKnowMoreModal({ store }) {
  if (!store) return false;

  if (isEntityType(store, 'ADMIN')) {
    return store.appends.push(<PaywallModal />);
  }

  return store.appends.push(<KnowMoreModal isBanner />);
}
