import React from 'react';
import { __ } from '../../../i18n';
import { Icon } from 'semantic-ui-react';
import { isNetwork } from '../../../app/Domain/UseCases/Unleash/validations/IsNetwork';
import '../../../assets/css/ui/MessageEntities.module.scss';

/**
 * @typedef {{
 * store,
 * sent: any,
 * replied: {
 *   totalCount: boolean,
 * },
 * received: {
 *   totalCount: boolean,
 * },
 * read: {
 *   totalCount: boolean,
 * },
 * }} IGetInfosWeb
 */

/**
 * @param {IGetInfosWeb} params
 */

export function infosWeb(store, sent, replied, received, read) {
  if (isNetwork(store.currentOrganization)) {
    return [
      {
        name: __('Sent'),
        summary: sent,
        icon: <Icon name="check" />,
        style: { padding: '21px 10px 21px 0px' },
      },
      {
        name: __('Received'),
        summary: received.totalCount,
        icon: <Icon color="black" name="check double" />,
        // eslint-disable-next-line sonarjs/no-duplicate-string
        style: { padding: '21px 0px 21px 10px' },
      },
      {
        name: __('Read'),
        summary: read.totalCount,
        icon: <Icon name="check double" style={{ color: '#0080FF' }} />,
        style: { padding: '21px 0px 21px 10px' },
      },
    ];
  }

  return [
    {
      name: __('Sent'),
      summary: sent,
      icon: <Icon name="check" />,
      style: { padding: '21px 10px 21px 0px' },
    },
    {
      name: __('Received'),
      summary: received.totalCount,
      icon: <Icon color="black" name="check double" />,
      // eslint-disable-next-line sonarjs/no-duplicate-string
      style: { padding: '21px 0px 21px 10px' },
    },
    {
      name: __('Read'),
      summary: read.totalCount,
      icon: <Icon name="check double" style={{ color: '#0080FF' }} />,
      style: { padding: '21px 0px 21px 10px' },
    },
    {
      name: __('Replied'),
      summary: replied.totalCount,
      icon: <Icon name="reply" />,
      style: { padding: '21px 0px 21px 10px' },
    },
  ];
}
