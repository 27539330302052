import { getMediasFromInput } from '../getMediasFromInput';
import { getThumbnailFromMedia } from '../getThumbnailFromMedia';
import { uniqueId } from 'lodash';

jest.mock('../getThumbnailFromMedia');
jest.mock('lodash', () => ({
  uniqueId: jest.fn(),
}));

describe('getMediasFromInput', () => {
  beforeEach(() => {
    jest.clearAllMocks();
  });

  it('should return an empty array if input is falsy', () => {
    expect(getMediasFromInput(null)).toEqual([]);
    expect(getMediasFromInput(undefined)).toEqual([]);
    expect(getMediasFromInput('')).toEqual([]);
  });

  it('should return an empty array if input has no medias', () => {
    const input = {};
    expect(getMediasFromInput(input)).toEqual([]);
  });

  it('should map medias correctly', () => {
    const input = {
      medias: [
        {
          origName: 'media1',
          type: 'IMAGE',
          // eslint-disable-next-line sonarjs/no-duplicate-string
          uri: 'http://example.com/media1',
          // eslint-disable-next-line sonarjs/no-duplicate-string
          filename: 'media1.jpg',
        },
        {
          type: 'VIDEO',
          // eslint-disable-next-line sonarjs/no-duplicate-string
          uri: 'http://example.com/media2',
          filename: 'media2.mp4',
        },
      ],
    };
    const env = 'test-env';
    uniqueId.mockReturnValue('unique-id');
    getThumbnailFromMedia.mockImplementation(
      (media, env) => `thumbnail-${media.uri}-${env}`,
    );

    const result = getMediasFromInput(input, env);

    expect(result).toEqual([
      {
        name: 'media1',
        type: 'image',
        preview: 'http://example.com/media1',
        thumbnail: 'thumbnail-http://example.com/media1-test-env',
        response: { filename: 'media1.jpg', uri: 'http://example.com/media1' },
      },
      {
        name: 'unique-id',
        type: 'video',
        preview: 'http://example.com/media2',
        thumbnail: 'thumbnail-http://example.com/media2-test-env',
        response: { filename: 'media2.mp4', uri: 'http://example.com/media2' },
      },
    ]);

    expect(uniqueId).toHaveBeenCalledTimes(1);
    expect(getThumbnailFromMedia).toHaveBeenCalledTimes(2);
  });

  it('should handle errors gracefully and return an empty array', () => {
    const input = {
      medias: [
        {
          type: 'IMAGE',
          uri: 'http://example.com/media1',
          filename: 'media1.jpg',
        },
      ],
    };
    getThumbnailFromMedia.mockImplementation(() => {
      throw new Error('Error generating thumbnail');
    });

    const result = getMediasFromInput(input);

    expect(result).toEqual([]);
  });
});
