import { __ } from '../../../../i18n';

// eslint-disable-next-line prettier/prettier
export function validateEntitySender(values) {
  if (Number(values.entityId) !== Number(this.props.store.currentEntity.id)) {
    this.props.store.appends.pop();
    this.setState({ onLeave: true }, () => {
      this.props.router.goBack();
      this.props.store.snackbar = {
        active: true,
        message: __('Not authorized'),
        success: false,
      };
    });
    return false;
  }

  return true;
}
