import { getTagsFromInput } from '../getTagsFromInput';

describe('getTagsFromInput', () => {
  it('should return tags', () => {
    const input = '{"tags": [{"id": 1}, {"id": 2}]}';
    const result = getTagsFromInput(input);
    expect(result).toEqual([1, 2]);
  });

  it('should return [] if input is empty', () => {
    const result = getTagsFromInput('');
    expect(result).toEqual([]);
  });

  it('should return tags if input is not empty', () => {
    const input = '{"tags": [{"id": 1}, {"id": 2}]}';
    const result = getTagsFromInput(input);
    expect(result).toEqual([1, 2]);
  });
});
