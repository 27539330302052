import { skipListMessages } from '../validateListMessages';

describe('skipListMessages', () => {
  test('should split the path correctly', () => {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    const path = '/some/path/to/edit';
    const params = { type: 'messagesToApprove' };
    const result = skipListMessages(path, params);
    expect(result).toBe(true);
  });

  test('should return false if the last part of the path is not "edit"', () => {
    const path = '/some/path/to/view';
    const params = { type: 'messagesToApprove' };
    const result = skipListMessages(path, params);
    expect(result).toBe(false);
  });

  test('should return false if params.type is not "messagesToApprove"', () => {
    const path = '/some/path/to/edit';
    const params = { type: 'otherType' };
    const result = skipListMessages(path, params);
    expect(result).toBe(false);
  });

  test('should return false if params is null', () => {
    const path = '/some/path/to/edit';
    const result = skipListMessages(path, null);
    expect(result).toBe(null);
  });

  test('should handle paths with leading and trailing slashes', () => {
    const path = '/some/path/to/edit/';
    const params = { type: 'messagesToApprove' };
    const result = skipListMessages(path, params);
    expect(result).toBe(true);
  });

  test('should handle paths with multiple slashes', () => {
    const path = '///some///path///to///edit///';
    const params = { type: 'messagesToApprove' };
    const result = skipListMessages(path, params);
    expect(result).toBe(true);
  });
});
