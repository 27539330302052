import { resendCommunication } from '../resendCommunication';
import gql from 'graphql-tag';

describe('resendCommunication', () => {
  const RESEND_COMMUNICATION_MUTATION = gql`
    mutation resendMessageNetwork($messageId: ID!) {
      resendMessageNetwork(input: { messageId: $messageId }) {
        success
        error
      }
    }
  `;

  it('should call mutate with correct parameters', () => {
    const client = {
      mutate: jest.fn(),
    };

    resendCommunication(client, 1);

    expect(client.mutate).toHaveBeenCalledWith({
      mutation: RESEND_COMMUNICATION_MUTATION,
      variables: {
        messageId: 1,
      },
      refetchQueries: [
        'EntityMessagesQuery',
        'MessageNodeEntityQuery',
        'OrganizationMessagesQuery',
        'MessageNodeOrganizationQuery',
      ],
    });
  });
});
