import { hasAttachments } from '../hasAttachments';

describe('hasAttachments', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      values: {
        medias: [],
        surveys: [],
        commitments: [],
        reports: [],
        charges: [],
        forms: [],
        signatures: [],
      },
    };
  });

  describe('when params is not provided', () => {
    it('should return false when values is not provided', () => {
      props.organization = null;
      const result = hasAttachments({ ...props });
      expect(result).toBe(false);
    });
  });

  describe('when params is provided', () => {
    it('should return true when it has medias', () => {
      props.values.medias = [
        {
          id: 1,
          name: 'media',
          url: 'url',
          type: 'IMAGE',
        },
      ];

      const result = hasAttachments({ ...props });
      expect(result).toBe(true);
    });

    it('should return true when it has surveys', () => {
      props.values.surveys = [
        {
          id: 1,
          name: 'Survey 1',
        },
      ];

      const result = hasAttachments({ ...props });
      expect(result).toBe(true);
    });

    it('should return true when it has commitments', () => {
      props.values.commitments = [
        {
          id: 1,
          name: 'Commitment 1',
        },
      ];

      const result = hasAttachments({ ...props });
      expect(result).toBe(true);
    });

    it('should return true when it has reports', () => {
      props.values.reports = [
        {
          id: 1,
          name: 'Report 1',
        },
      ];

      const result = hasAttachments({ ...props });
      expect(result).toBe(true);
    });

    it('should return true when it has charges', () => {
      props.values.charges = [
        {
          id: 1,
          name: 'Charge 1',
        },
      ];

      const result = hasAttachments({ ...props });
      expect(result).toBe(true);
    });

    it('should return true when it has forms', () => {
      props.values.forms = [
        {
          id: 1,
          name: 'Form 1',
        },
      ];

      const result = hasAttachments({ ...props });
      expect(result).toBe(true);
    });

    it('should return true when it has signatures', () => {
      props.values.signatures = [
        {
          id: 1,
          name: 'Signature 1',
        },
      ];

      const result = hasAttachments({ ...props });
      expect(result).toBe(true);
    });
  });
});
