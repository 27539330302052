import { getRecipients } from '../getRecipients';

const entities = [
  {
    id: 1,
    fullname: 'John Doe',
    roles: { nodes: [{ id: 1, name: 'group 1' }] },
  },
  {
    id: 2,
    fullname: 'Jane Doe',
    roles: { nodes: [{ id: 2, name: 'group 2' }] },
  },
  {
    id: 3,
    fullname: 'Teste',
    roles: { nodes: [{ id: 1, name: 'group 1' }] },
  },
];

const groups = [
  { id: 1, name: 'group 1', entities: { nodes: [entities[0], entities[2]] } },
  { id: 2, name: 'group 2', entities: { nodes: [entities[1]] } },
];

const recipientsExpectedResult = [
  {
    id: 1,
    fullname: 'John Doe',
    groups: [{ id: 1, name: 'group 1' }],
  },
  {
    id: 2,
    fullname: 'Jane Doe',
    groups: [{ id: 2, name: 'group 2' }],
  },
  {
    id: 3,
    fullname: 'Teste',
    groups: [{ id: 1, name: 'group 1' }],
  },
];

describe('Given the getRecipients function', () => {
  it('should return empty recipients when no group or entities array is sent', () => {
    const recipients = getRecipients([], []);
    expect(recipients).toEqual([]);
  });

  it('should return recipients when entities array is sent', () => {
    const recipients = getRecipients([], entities);
    expect(recipients).toEqual(recipientsExpectedResult);
  });

  it('should return recipients when groups array is sent', () => {
    const recipients = getRecipients(groups, []);
    expect(recipients).toEqual([
      recipientsExpectedResult[0],
      recipientsExpectedResult[2],
      recipientsExpectedResult[1],
    ]);
  });

  it('should return recipients when groups and entities array is sent', () => {
    const recipients = getRecipients(groups, entities);
    expect(recipients).toEqual(recipientsExpectedResult);
  });
});
