import { getRecipientsFromHelpers } from '../getRecipientsFromHelpers';

describe('getRecipientsFromHelpers', () => {
  it('should return null when passed empty input', () => {
    const helpers = null;
    const allGroups = [];
    const allRecipients = [];
    const expectedOutput = null;
    const result = getRecipientsFromHelpers(helpers, allGroups, allRecipients);
    expect(result).toEqual(expectedOutput);
  });

  it('should return an object with the necessary data to send an approval message', () => {
    const helpers = [
      { id: 1, selected: true },
      { id: 2, selected: false },
    ];

    const helpersStringified = JSON.stringify(helpers);
    const expectedOutput = undefined;

    const result = getRecipientsFromHelpers(helpersStringified);
    expect(result).toEqual(expectedOutput);
  });
});
