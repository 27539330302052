import { getMediaButtonStyles } from '../getMediaButtonStyles';

describe('getMediaButtonStyles', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      isMobile: false,
      shouldShowButton: false,
      hasPersonalizedFilesAdded: false,
    };
  });

  it('should return empty object when props is not provided', () => {
    const result = getMediaButtonStyles({});
    expect(result).toStrictEqual({});
  });

  it('should return mobile object when only isMobile is true', () => {
    props.isMobile = true;
    props.shouldShowButton = false;

    const result = getMediaButtonStyles(props);
    expect(result).toStrictEqual({ paddingTop: '11px' });
  });

  it('should return mobile object when shouldShowButton and hasPersonalizedFilesAdded is true', () => {
    props.isMobile = false;
    props.shouldShowButton = true;
    props.hasPersonalizedFilesAdded = true;

    const result = getMediaButtonStyles(props);
    expect(result).toStrictEqual({
      borderRadius: '0.5rem',
      border: '1px solid rgba(0,0,0,.3)',
      display: 'flex',
      margin: '0px 4px',
      minHeight: '36px',
      padding: '0.5rem',
    });
  });
});
